import React, { useEffect, useState } from "react";
import "./AuditRows.css";
import { extractFileName, extractUserName, capitalizeFirstLetter } from "../../utils/util";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";

export const getColor = (status) => {
  //console.log(status, "statuscolor:::");
  const red = [
    "Flagged for Review",
    "Reviewed,no change",
    "Reviewed,query required",
  ]; // #F8698521;
  const blue = [
    "Reviewed,reclaimed",
    "Reviewed,rebilled",
    "Reviewed,query required",
  ]; // #00395B
  const gray = ["Reviewed,query sent", "Reviewed,recoded"]; // #344054;
  const passed = "passed"; //#E4F5FF
  const failed = "Failed"; //#feecef;
  if (passed === status) {
    return "passed";
  }
  if (failed === status) {
    return "failed";
  }

  if (red.includes(status)) return "red";
  if (blue.includes(status)) return "blue";
  if (gray.includes(status)) return "gray";
};

const AuditRowCard = ({
  handleRowClick,
  row,
  isActive,
  flagauditRows,
  unflage,
  auditDetails,
  selectedEpisodeRows,
  setSelectedEpisodeRows
}) => {
  //console.log("flagauditRows", flagauditRows);
  // console.log('newsssss',row)
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const superUserReadOnly = permissions?.super_user_readonly || false;
  const superUser = permissions?.super_user || false;
  const admin = permissions?.admin || false;
  let PrivilegedUser = "";
  if ("Privileged User" in permissions) {
    PrivilegedUser = permissions["Privileged User"];
  } else {
    PrivilegedUser = false;
  }
  const userID = JSON.parse(sessionStorage.getItem("userId"));
  const workflow =
    permissions && Array.isArray(permissions.WorkflowCompanion)
      ? permissions.WorkflowCompanion[0]
      : "";
  const workflowReadOnly =
    permissions && Array.isArray(permissions.WorkflowCompanion)
      ? permissions.WorkflowCompanion[0]
      : "";

  const handleRowSelection = (event, rowData) => {
    if (event.target.checked) {
      const selectedData = [...selectedEpisodeRows, rowData.hcp_line_id];
      setSelectedEpisodeRows(selectedData);
    } else {
      const newAssignedData = selectedEpisodeRows.filter(
        (epsoideId) => epsoideId !== rowData.hcp_line_id
      );
      setSelectedEpisodeRows(newAssignedData);
    }
  };
const getFormattedDate = (row) => {
    const { hcp_line_commented_at, hcp_line_created_at, hcp_line_updated_at } = row;

    // Helper function to parse date string (DD-MM-YYYY HH:MM:SS) into a Date object
    const parseDate = (dateStr) => {
        if (!dateStr) return null;

        const [datePart, timePart] = dateStr.split(" ");
        const [day, month, year] = datePart.split("-").map(Number);
        const [hours, minutes, seconds] = timePart.split(":").map(Number);

        return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)); // Convert to UTC
    };

    // Convert to Date objects
    const createdAt = parseDate(hcp_line_created_at);
    const updatedAt = parseDate(hcp_line_updated_at);
    const commentedAt = parseDate(hcp_line_commented_at);

    if (!createdAt || !updatedAt) {
        return "Invalid Date";
    }

    // Case 1: If `hcp_line_commented_at` is NULL, check if created and updated times are equal
    if (!commentedAt) {
        if (createdAt.getTime() === updatedAt.getTime()) {
            return "No updations";
        }
        return new Date(Math.max(createdAt.getTime(), updatedAt.getTime())).toLocaleString();
    }

    // Case 2: If `hcp_line_commented_at` is NOT NULL, show the latest date
    return new Date(Math.max(createdAt.getTime(), updatedAt.getTime(), commentedAt.getTime())).toLocaleString();
};

  return (
    <>
      <tr
        className={`auditrow ${isActive && "active"}`}
        onClick={() => {
          flagauditRows ? unflage(row) : handleRowClick(row);
        }}
      >
       <td>
          <input
            type="checkbox"
            checked={selectedEpisodeRows?.some(
              (epsoide) => epsoide === row.hcp_line_id
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleRowSelection(e, row);
            }}
            disabled={
              !superUser &&
              !admin &&
              (((workflow === "fullAccess" || workflow === "read") &&
                superUserReadOnly) ||
                (workflow === "read" && superUserReadOnly) ||
                (workflow === "fullAccess" && superUser) ||
                (workflow === "read" && superUser) ||
                (PrivilegedUser && workflow === "fullAccess") ||
                (PrivilegedUser && workflow === "read") ||
                (PrivilegedUser && superUserReadOnly) ||
                (PrivilegedUser && superUser)) &&
              row.assigned_to_user_id !== userID
            }
            title={
              !superUser &&
              !admin &&
              (((workflow === "fullAccess" || workflow === "read") &&
                superUserReadOnly) ||
                (workflow === "read" && superUserReadOnly) ||
                (workflow === "fullAccess" && superUser) ||
                (workflow === "read" && superUser) ||
                (PrivilegedUser && workflow === "fullAccess") ||
                (PrivilegedUser && workflow === "read") ||
                (PrivilegedUser && superUserReadOnly) ||
                (PrivilegedUser && superUser)) &&
              row.assigned_to_user_id !== userID &&
              row.assigned_to_user_id !== userID
                ? "You can't assign this episode because you're not the owner"
                : ""
            }
            style={{
              height: "15px",
              width: "15px",
            }}
          />
        </td>
        <td>
          {" "}
          {row.priority_value &&
          row.priority_value.some((val) => val !== null) ? (
            row.priority_value.map((value, index) => (
              <span
                key={index}
                className={`audit-count p${value}`}
                style={{ marginRight: "5px" }}
              >
                P{value}
              </span>
            ))
          ) : (
            <span className="audit-count p5">P5</span>
          )}
        </td>
        <td>{row.file_name}</td>
        <td>{row.coded_by}</td>
        <td>{capitalizeFirstLetter(row.uploaded_by_user_name)}</td>
        <td>{row.date}</td>
        <td>{capitalizeFirstLetter(row.assigned_by_user_name)}</td>
        <td>{capitalizeFirstLetter(row.assigned_to_user_name)}</td>
        <td>
          {row?.audit_rule_short_description?.map((eachDesc, index) => {
            return (
              <span key={index}>
                {eachDesc}
                {index < row.audit_rule_short_description.length - 1 && ", "}
              </span>
            );
          })}
        </td>
        <td>
          <div className={`audit-status-btn ${getColor(row.status)}`}>
            {row.status}
          </div>
        </td>
        <td>{row.episode_identifier}</td>
        <td>{getFormattedDate(row)}</td>
      </tr>
    </>
  );
};

function AuditRows({
  handleRowClick,
  auditRows,
  selectedIndex,
  hasMore,
  loadMore,
  isRowsLoading,
  flagauditRows,
  auditDetails,
  seteditFlageData,
  getAuditDetailsById,
  setSelectedIndex,
  setIsRowClicked,
  setPriorityVal5,
  selectedEpisodeRows,
  setSelectedEpisodeRows,
  isAllEpisodesSelected,
  setIsAllEpisodesSelected,
}) {
  let unflage = (row) => {
    //console.log("My row", row);
    seteditFlageData(row);
    //console.log("AUDITROW::::::", row);
    //console.log('clciked')
    getAuditDetailsById(row.hcp_line_id);
    const index = auditRows.findIndex(
      (data) => data.hcp_line_id === row.hcp_line_id
    );
    setSelectedIndex(index);
    setIsRowClicked(true);
    setPriorityVal5(row.priority_value === 5 ? true : false);
  };

  useEffect(() => {
    if (isAllEpisodesSelected) {
      setSelectedEpisodeRows(auditRows.map((row) => row.hcp_line_id));
    } else {
      setSelectedEpisodeRows([]);
    }
  }, [auditRows, isAllEpisodesSelected]);

  const selectAllAssignedToRows = (event) => {
    const sendAllIds = auditRows.map((id) => id.hcp_line_id);
    if (event && event.target.checked) {
      setSelectedEpisodeRows(sendAllIds);
      setIsAllEpisodesSelected(true);
    } else {
      setSelectedEpisodeRows([]);
      setIsAllEpisodesSelected(false);
    }
  };
  //console.log(flagauditRows,'jyug',auditRows);
  return (
    <>
     <InfiniteScroll
        className=" audits-main"
        hasData={hasMore}
        loadMore={() => loadMore()}
        isLoading={isRowsLoading}
      >
        <table id="audits-main" className="">
          <thead>
            <tr>
            <th>
                <input
                  type="checkbox"
                  onChange={selectAllAssignedToRows}
                  checked={isAllEpisodesSelected}
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              </th>
              <th>Select All</th>
              <th>Document Name</th>
              <th>Coded By</th>
              <th>Uploaded By</th>
              <th>Date</th>
              <th>Assigned By</th>
              <th>Assigned To</th>
              <th>Description</th>
              <th>Status</th>
              <th>Episode Identifier</th>
              <th>Last Audited Date</th>
            </tr>
          </thead>
          <tbody>
          {auditRows &&
              auditRows.map((row, index) => (
                <AuditRowCard
                  key={index}
                  row={row}
                  handleRowClick={handleRowClick}
                  isActive={selectedIndex === index}
                  flagauditRows={flagauditRows}
                  unflage={unflage}
                  auditDetails={auditDetails}
                  selectedEpisodeRows={selectedEpisodeRows}
                  setSelectedEpisodeRows={setSelectedEpisodeRows}
                />
              ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
}

export default AuditRows;
