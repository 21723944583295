// src/axiosInstance.js
import axios from 'axios';
import { continueToken } from './App';


const axiosInstance=axios.create({
  
});
// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the config to include your custom headers
    const token = sessionStorage.getItem('accessToken'); // or wherever you store your token
    if (token) {
      config.headers['authorizationToken'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor (optional)
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // Handle errors globally
//     if(error.response && (error.response.status===401 && error.response.data.message=="Unauthorized" || error.response.status===403)){
//       let errorMessage='';
//       if(error.response.status===401 && error.response.data.message){
//         errorMessage='Please login again.'
//       }else if(error.response.status===403){
//         errorMessage='You do not have permission to access this resource'
//       }
//       window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
//     }else if(error.response.data.permissions){
//       let errorMessage='';
//       errorMessage=error.response.data.permissions;
//       window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
//     }
//     return Promise.reject(error);
//   }
// );

let isRefreshing = false;
let failedRequestsQueue = [];

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, "error messages");

    if (error.response) {
      const status = error.response.status;
      const errorMessage = error.response.data?.message;

      // Handle Unauthorized (401) or Forbidden (403) errors
      if (status === 401 || status === 403) {
        const originalRequest = error.config;
        const hasTokenFailed = sessionStorage.getItem("hasTokenFailed") === true
        if(hasTokenFailed){
          console.log("Token refersh already failed, not retrying...")
          return Promise.reject(error)
        }

        if (!isRefreshing) {
          isRefreshing = true;
          window.dispatchEvent(
            new CustomEvent("tokenExpired", {
              detail: { errorMessage: "Please log in again."},
            })
          );

          return continueToken().then((newAccessToken) => {
            isRefreshing = false;
            if(!newAccessToken){
              console.log("No new access token reacived")
              sessionStorage.setItem("hasTokenFailed", "true")
              return Promise.reject(error)
            }

            // Retry all failed requests with the new access token
            failedRequestsQueue.forEach((cb) => cb(newAccessToken));
            failedRequestsQueue = [];

            originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
          }).catch(() => {
            isRefreshing = false;
            failedRequestsQueue = [];
            return Promise.reject(error);
          });
        }

        // Queue failed requests until the token refresh is done
        return new Promise((resolve) => {
          failedRequestsQueue.push((newAccessToken) => {
            originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);


export default axiosInstance;
